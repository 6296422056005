.div_container_main_article_header_home {
    flex-direction: column;
    // display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 90%;
    color: #ffffff;
    margin: 2.5rem auto;
    // background: linear-gradient(180deg, #0e263c 0, #091523);
    background: linear-gradient(180deg, #070D1B 0, #091523);
    border-radius: 8px;
}
.div_container_main_article_header {
    flex-direction: column;
    // display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70%;
    color: #ffffff;
    margin: .5rem auto 3rem auto;
    // background: linear-gradient(180deg, #0e263c 0, #091523);
    background: linear-gradient(180deg, #070D1B 0, #091523);
    border-radius: 8px;
}
.div_body_article {
    flex-direction: column;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    // width: 60%;
    // margin-top: 10rem;
    // background: #2e2e2e94;
    border-radius: 5px;
    padding: 0px 0px 25px 0px;
    // box-shadow: 0 0 5px #ffffff, 0 0 0px #a3f4f4, 0 0 7px #abf5f5;
    // box-shadow: 0 0 5px #ffffff, 0 0 0px #fcfcfc, 0 0 7px #ffc107;
}
.article_content {
    width: 95%;
    margin: 0 auto;
}
.article_content h1,
h2,
h3,
h4 {
    color: #ffb600;
    text-align: left;
    font-size: 20px;
    margin: 25px 0px 15px 0px;
}
.article_content p {
    font-size: 16px;
    text-align: left;
    text-indent: 30px;
    margin: 8px 0px 20px 0px;
}
.image_article {
    width: 100%;
    border-radius: 5px;
}
.ol_sty_decimal {
    list-style-type: decimal;
    padding: 0px 0px 0px 18px;
}
.ol_sty_disclosed {
    list-style-type: disclosure-closed;
    padding: 0px 0px 0px 18px;
}
.ol_sty_square {
    list-style-type: square;
    padding: 0px 0px 0px 18px;
}
.ol_sty_none {
    list-style-type: none;
    padding-left: 0rem;
}
.ol_home {
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    & li {
        margin-bottom: 10px;
    }
}
.italic_font {
    font-style: italic;
}
.promotion_image_pro_pro {
    width: 100%;
    height: auto;
    border-radius: 5px;
}
.div_img_pro_pro {
    padding: 15px;
}
.div_btn_back {
    width: 70%;
    display: flex;
    margin: 5.5rem auto auto auto;
    padding: 0.5rem 0rem;
    // background-color: red;
    & button {
        background: transparent;
        border: none;
        color: #fff;
        & svg {
            font-size: 1rem;
        }
    }
}
@media only screen and (max-width: 768px) {
    .div_btn_back {
        width: 95%;
    }
    .div_img_pro_pro {
        padding: 0px 0px 30px 0px;
    }
    .promotion_image_pro_pro {
        width: 100%;
        height: auto;
    }
    .div_body_article {
        // width: 90%;
    }
    .div_container_main_article_header_home {
        margin: 1rem auto;
        width: 95%;
    }
    .div_container_main_article_header {
        margin: .5rem auto 5rem auto;
        width: 95%;
    }
    .contact_image_pro {
        width: 100%;
        height: auto;
    }
    .p_article_main {
        font-size: 14px;
    }
    .div_img_article {
        margin: 0 auto 1rem 0px;
        padding: 0px;
    }
    .div-ol-padding {
        // padding: 0px 0px 0px 18px;
    }
    .ol_home {
        font-size: 14px;
        // padding: 0;
        margin: 10px auto;
    }
    .image_article {
        width: 100%;
    }
    .article_content h1,
    h2,
    h3,
    h4 {
        font-size: 18px;
        margin: 18px 0px 15px 0px;
        text-align: center;
    }
    .article_content p {
        font-size: 14px;
        text-indent: 15px;
        margin: 8px 0px 18px 0px;
    }
    .article_content {
        width: 90%;
    }
}