.card-promotion-style {
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
}
.title-menu {
  font-size: 36px;
  font-weight: bold;
  background: linear-gradient(to bottom, #e65c00, #f9d423);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-promotion {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #1b1b1b !important;
  .card-footer {
    height: 60px;
  }
  .card-header {
    color: #fff !important;
    font-size: 22px;
    border-bottom: none !important;
    padding: 0 2%;
    .card-header-tital {
      color: #ffc23c;
    }
  }
  .card-text {
    color: #fff !important;
    font-size: 26px;
    border-bottom: none !important;
    padding: 0 2%;
  }
  .btn-detail {
    display: block;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    border-radius: 25px;
    color: #000000;
    border: transparent;
    width: 100%;
    background: #abbaab;
    background: -webkit-linear-gradient(to right, #ffffff, #abbaab);
    background: linear-gradient(to right, #ffffff, #abbaab);
    box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
  }
  .btn-bonus {
    display: block;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    border-radius: 25px;
    color: #323232;
    border: transparent;
    width: 100%;
    background: #ff8008;
    background: -webkit-linear-gradient(to bottom, #ffc837, #ff8008);
    background: linear-gradient(to bottom, #ffc837, #ff8008);
    box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
  }
}
.img-promotion {
  width: 100%;
  height: auto;
}
.card-promotion:hover {
  box-shadow: 0 0 11px rgba(248, 163, 6, 0.778);
}
.btn-receive {
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  font-weight: 600;
  height: 40px;
  border-radius: 25px;
  color: #323232;
  border: transparent;
  width: 100%;
  background: #ff8008;
  background: -webkit-linear-gradient(to bottom, #ffc837, #ff8008);
  background: linear-gradient(to bottom, #ffc837, #ff8008);
  box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
}
.btn-promotion-close {
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  font-weight: 600;
  height: 40px;
  border-radius: 25px;
  color: #fff;
  border: transparent;
  width: 100%;
  background: #ada996; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eaeaea,
    #dbdbdb,
    #f2f2f2,
    #ada996
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eaeaea,
    #dbdbdb,
    #f2f2f2,
    #ada996
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
.container-promotion {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  top: 10px;
  left: 35%;
  color: #fcfcfc;
  font-size: 25px;
  font-weight: bolder;
}
