.are--headerNav {
  width: 100%;
  height: 172px;
  background-color: #000;
  position: fixed;
  top: 0;
  z-index: 3;
  // background: transparent url(../img/Bg-header.6a962224.jpg) 100% repeat-x;
}
.are--headerNav .lay-screen {
  width: 1440px;
}
.are--headerNav .square,
.are--headerNav .squarePart {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  margin-top: 2px;
}
.lnk--logo {
  width: 224px;
  height: 63px;
  margin-left: 15px;
  // background: transparent url(../img/logo.cc841a7c.png) 0 no-repeat;
}
.ul_tab_menu_second li:hover {
  .spn_tab_menu_second {
    color: #cf983f;
  }
  font-weight: 600;
  border-radius: 3px;
  --border-size: 0.1rem;
  border: var(--border-size) solid transparent;
  background-image: linear-gradient(171deg, #070D1B, #091523 51.68%, #004463),
    linear-gradient(90deg, #ffffff, #ffffff 31.53%, #ffffff 64.87%, #ffffff);
  border-image: conic-gradient(from var(--angle), #d53e3300 0deg 90deg, #ffffff 90deg 180deg, #377af500 180deg 270deg, #ffffff 270deg 360deg) 1 stretch;
}
.active_header{
  .spn_tab_menu_second {
    animation: animatelight 5s ease 0s infinite normal none;
    color: #ffc107;
  }
}
.no_active_header {
  .spn_tab_menu_second {
    color: #fff;
  }
}
.btn_free_credit {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 40px;
  border-radius: 12px;
  color: #fcfcfc;
  border: transparent;
  width: 100%;
}
.btn-free-credit-des {
  // display: block;
  // margin-right: auto;
  // margin-left: auto;
  // font-size: 14px;
  width: 80px;
  height: 40px;
  border-radius: 25px;
  // color: #fcfcfc;
  // border: transparent;
  // width: 100%;
  background: -webkit-linear-gradient(to right, #fbff00, #ffd900);
  background: linear-gradient(to right, #fbff00, #ffd900);
}
/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  .ul_tab_menu_second li {
    animation: rotate 3s linear infinite, opacityChange 3s infinite alternate;
  }
  .btn_modal_login{
    animation: rotate 3s linear infinite, opacityChange 3s infinite alternate;
  }
}
.div_img_balanc {
  display: flex;
  align-items: center;
}
.div_img_point {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.img_contact_header {
  width: 35px;
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .div_marquee_header {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .img_contact_header {
    display: none;
  }
  .div_marquee_header {
    display: none;
  }
  .btn_free_credit{
    font-size: 14px;
  }
  .spn_tab_menu_second {
    font-size: 12px;
  }
  .ul_tab_menu_second li:hover {
    border-radius: 0px;
    --border-size: 0rem;
    border: var(--border-size) solid transparent;
    background-image: none;
    border-image: none;
    .spn_tab_menu_second {
      color: #cf983f;
    }
  }
}