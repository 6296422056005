.modal_modal .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_modal .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    border-bottom: none;
}
.modal_modal_bg>div>.modal-content>.modal-header>.modal-title {
    font-size: 20px !important;
}
.modal_modal_exit .modal-content {
    background: #fff;
    box-shadow: none;
    color: #fff;
}
.modal_modal_exit .modal-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
}
.title_popup_load {
    margin: 0 auto !important;
    color: #fff;
}
.div_des_rules p {
    font-size: 16px;
}
.modal_rules_footer button {
    padding: 5px;
    color: #fff;
    background-color: #0096e3;
    border-color: #0096e3;
    width: 100%;
    border: none;
    box-shadow: -1px 2px 5px black;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal_modal_login .modal-content {
    border: none !important;
    background: transparent !important;
}
.modal_modal_login .modal-header {
    background: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 5px !important;
}
.modal_modal_login .modal-content .modal-body {
    padding: 0px !important;
}
@media only screen and (max-width: 768px) {
    .div_des_rules p {
        font-size: 14px;
    }
    .div_des_rules li {
        font-size: 14px;
    }
    .div_des_rules strong {
        font-size: 14px;
    }
}