.footer {
  height: 285px;
  // background: url("../image/footer.png");
}
.lay-screen {
  padding-top: 175px;
  color: #fcfcfc;
  display: block;
  margin-left: auto;
  margin-left: auto;
  text-align: center;
}
.icon-game-camp {
  margin-left: 5px;
  margin-right: 5px;
}
.center-icon {
  width: 55px;
  -webkit-animation: moverandboxshadow 1s infinite alternate;
  animation: moverandboxshadow 1s infinite alternate;
  // animation: rotation infinite 7s linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav_play span {
  // animation: flicker 1.5s infinite alternate;
  color: #fff;
  font-size: 14px;
}
.swal_ficker{
  animation: flicker2 1.5s infinite alternate;
  font-size: 20px;
  font-weight: bold;
}
@keyframes flicker2 {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px rgb(223, 187, 29),
      0 0 80px rgb(223, 187, 29), 0 0 90px rgb(223, 187, 29), 0 0 100px rgb(223, 187, 29), 0 0 150px #0fa;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
  }
}
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
  }
}
@media only screen and (max-width: 768px) {
  // NEW
  .div_main_icon {
    align-items: center;
    // background: linear-gradient(135deg,#ffd15c 15%,#f6db96 50.42%,#9b7a24 85.83%);
    border-radius: 100%;
    box-shadow: 0 0 10px #ffc107, inset 0 0 2px #00000080;
    display: flex;
    height: 58px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -35px;
    transform: translate(-50%);
    width: 58px;
  }
  .link_bar_menu_home_login {
    flex-direction: column;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 0px;
    font-size: 12px;
}
  .foter_headermain {
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 10;
  }
  .foter_headermain_sec {
    height: 70px;
    position: relative;
    width: 100%;
    bottom: 0;
    display: flex;
    margin: 0 auto;
  }
  .foter_headermain_sec1 {
    width: 100%;
    display: flex;
  }
  .div_footer_1 {
    flex: 1;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg,#000 0,#0c112c 100%),linear-gradient(0deg,#191f3a,#191f3a)!important;
  }
  .div_footer_3 {
    flex: 1;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg,#000 0,#0c112c 100%),linear-gradient(0deg,#191f3a,#191f3a)!important;
  }
  .foter_headermain_sec2 {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .foter_headermain_sec2_1 {
    display: flex;
    height: 100%;
    padding-bottom: 14px;
    width: 100%;
    margin: 0 auto;
    align-items: end;
    max-width: 630px;
    padding: 10px 0px;
  }
  .footer-content {
    font-size: 10px;
  }
  .lay-screen {
    padding-top: 0px;
    color: #fcfcfc;
    display: block;
    margin-left: auto;
    margin-left: auto;
    text-align: center;
  }
}