.btn-summit {
  width: 94% !important;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border-radius: 15px;
  color: #000000;
  border: transparent;
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
}
// .modal-header {
//   color: #fff;
//   border-bottom: #b58c4a solid;
// }
.modal_register {
  color: #fff;
}
.modal-content {
  background-color: unset !important;
  // background: linear-gradient(180deg, #070D1B 0, #091523);
  // box-shadow: 0 0 5px #ffffff, 0 0 5px #b58c4a, 0 0 10px #b58c4a;
  // border: none;
}
.btn_modal_login {
  color: #000 !important;
  font-weight: 600;
  width: 100%;
  border: none !important;
  padding: 10px;
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
  display: inline-block;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition: color 0.3s !important;
}
.btn_modal_login:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-out;
  border-radius: 15px;
}
.modal_card_withdraw {
  font-size: 16px;
  font-weight: 200;
}
.modal_card_withdraw p {
  text-align: left;
}
.btn_modal_login:hover {
  color: white;
}
#basic-menu {
  top: 0;
}
.btn_modal_login:hover:before {
  transform: scaleX(1);
}
.logo-login {
  -webkit-animation: moverandboxshadow 1s infinite alternate;
  animation: moverandboxshadow 1s infinite alternate;
}
@-webkit-keyframes moverandboxshadow {
  0% {
    transform: translateY(0);
    // -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
    // filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
  }
  100% {
    transform: translateY(-5px);
    // -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
    // filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
  }
}
@keyframes moverandboxshadow {
  0% {
    transform: translateY(0);
    // -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
    // filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
  }
  100% {
    transform: translateY(-5px);
    // -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
    // filter: drop-shadow(0px 0px 2px rgb(255, 199, 46));
  }
}
.div-container {
  width: 50%;
  margin: 0 auto;
}
.btn-close-1 {
  font-size: 16px;
  height: 40px;
  border-radius: 25px;
  color: #323232;
  border: transparent;
  width: 100%;
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #ece9e6, #ffffff);
  background: linear-gradient(to right, #ece9e6, #ffffff);
  box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
}
.btn-affiliate {
  font-size: 16px;
  height: 40px;
  border-radius: 25px;
  color: #fcfcfc;
  border: transparent;
  width: 100%;
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
}
.nav-tabs-affiliate .nav-item.show .nav-link,
.nav-tabs-affiliate .nav-link.active {
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
  border: none !important;
}
.nav-tabs-affiliate>.nav-item>.nav-link {
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  width: 100%;
}
.nav-tabs-affiliate {
  border-bottom: none;
  border: 1px solid #505050;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.nav-tabs-affiliate .nav-link:hover {}
.icon-bottom-navbar {
  display: none;
  // animation: wiggle 2s linear infinite;
}
.text-manual {
  font-size: 18px;
  color: #fcfcfc;
}
.text-manual-item {
  font-size: 16px;
  color: #fcfcfc;
  margin-top: -7px;
}
.img-seo {
  width: 100%;
  border-radius: 15px;
}
.container-activity-guide {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}
.container-reward {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  // height: 100vh;
  background-color: #222222;
}
.container-affiliate {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}
.container-affiliate-detail {
  font-size: 18px;
  color: #fcfcfc;
  text-indent: 50px;
  letter-spacing: 0.02em;
}
.div-container-affiliate {
  width: 80%;
  margin: 0 auto;

}
.info_web {
  font-size: 12px;
  color: #bf8c26;
}
.div_affiliate_detail {
  background-color: #212A3E;
  padding: 15px 20px;
  border-radius: 12px;
  align-items: center;
}
.btn-home-affiliate {
  border-radius: 13px;
  padding: 5px 15px;
  border: none !important;
  color: #fcfcfc;
  background: #7f00ff;
  background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  background: linear-gradient(to right, #e100ff, #7f00ff);
}
.btn-rewards {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 7px;
  border: none !important;
  padding: 7px 10px;
  color: #181823;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}
.btn-reward-summit {
  height: 40px;
  width: 130px;
  border-radius: 5px;
  border: none;
  padding: 2px 15px;
  color: #fcfcfc;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 5%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}
.btn-reward-close {
  height: 40px;
  width: 130px;
  border-radius: 5px;
  border: none;
  padding: 2px 15px;
  color: #000000;
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #ffffff, #ece9e6);
  background: linear-gradient(to right, #ffffff, #ece9e6);
}
.container-promotion-detail {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}
.div-container-promotion {
  width: 80%;
  margin: 0 auto;
}
.btn-promotion-detail {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 7px;
  border: none !important;
  padding: 7px 15px;
  color: #fcfcfc;
  background: #7f00ff;
  background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  background: linear-gradient(to right, #e100ff, #7f00ff);
}
.image-promotion-detail {
  width: 90%;
}
.label-promotion-detail {
  color: #bf94ff;
}
.img-affiliate {
  width: 50%;
}
.text-reward-name {
  font-size: 18px;
  font-weight: bold;
  color: #e9b21a;
}
.text-reward-info {
  font-size: 16px;
  margin-top: -8px;
  color: #fcfcfc;
}
.text-header-page {
  background: -webkit-linear-gradient(#4568dc, #b06ab3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}
.progress-bar-red {
  background: red;
}
.progress-bar .bg-warning {
  background-color: #e100ff;
}
.progress {
  background: black;
}
.bg-bg-red {
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  padding: 0 30px;
}
.bg-bg-yellow {
  background: #e65c00;
  background: -webkit-linear-gradient(to right, #f9d423, #e65c00);
  background: linear-gradient(to right, #f9d423, #e65c00);
  padding: 0 30px;
}
.bg-bg-green {
  background: #5433ff;
  background: -webkit-linear-gradient(to right, #a5fecb, #20bdff, #5433ff);
  background: linear-gradient(to right, #a5fecb, #20bdff, #5433ff);
  padding: 0 30px;
}
.text-details {
  font-size: 16px;
}
.card-reward {
  width: 100%;
}
.btn-fixed2-active {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  border: 0px !important;
  color: #fcfcfc !important;
  // background: #da22ff;
  background: -webkit-linear-gradient(to right, #9733ee, #da22ff);
  // background: linear-gradient(to right, #9733ee, #da22ff);
  background: linear-gradient(to right, #d6bf79, #b58c4a);
}
.btn-fixed2-active-noborder {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #e3acf9 !important;
  color: white !important;
}
.btn-fixed2-active-noborder:hover,
.btn-fixed2-active-noborder:focus,
.btn-fixed2-active-noborder:active {
  // background-color: #2d033b !important;
  background-color: #b58c4a !important;
}
.col-20percent {
  display: block;
  flex: 0 0 15% !important;
  max-width: 15% !important;
}
.btn-fixed {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  background: #fcfcfc;
  border: 0px !important;
  color: #000000 !important;
}
.btn-fixed:hover,
.btn-fixed:focus,
.btn-fixed:active {
  background-color: #b19335 !important;
}
.btn-fixed-success {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #b19335;
  border: 0px !important;
  color: white !important;
  border: 3px solid #00a1ff !important;
}
.btn-fixed-success:hover,
.btn-fixed-success:focus,
.btn-fixed-success:active {
  background-color: #b19335 !important;
}
.btn-fixed2 {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #828387 !important;
  border: 0px !important;
  color: white !important;
}
.border-active {
  // border: 4px solid #6f1ab6 !important;
  border: 4px solid #198754 !important;
}
.inpu-query {
  width: 350px;
}
.mt-3_5rem {
  margin-top: 3.5rem !important;
}
.btn-vbvb {
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  padding: 10px 10px;
  background: linear-gradient(to right,
      #b58c4a,
      #d6bf79,
      #ecdebd,
      #d6bf79,
      #b58c4a);
  color: #091523;
}
.border-table {
  width: 100%;
  background-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  background-origin: border-box;
  border-spacing: 5px;
  border: 7px solid transparent;
}
.border-table td {
  background: #0F0F0F;
  color: #fcfcfc;
}
.box-content {
  padding: 0 20%;
}
.tab-games {
  background: url("../image/BG.png");
  background-size: cover;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  border-left-width: 0;
  border-right-width: 0;
  border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
  padding: 7px 0;
}
.tab_article {
  // background: url("../image/BG.png");
  background-size: cover;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  border-left-width: 0;
  border-right-width: 0;
  border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
  // padding: 7px 0;
}
.btn-history {
  width: 40%;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  border-radius: 15px;
  color: #000000;
  border: transparent;
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
}
.spn_text_link_mobile {
  font-weight: 600;
}
.list_menu_mobile:hover {
  .spn_text_link_mobile {
    color: #e2dddd;
  }
}
.bottom-navbar {
  display: none;
}
.contact_24h {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 25px;
}
.img_ccontact_24h {
  cursor: pointer;
}
.card_freecredit {
  background: -webkit-linear-gradient(to top, rgb(0 0 0 / 85%), #424242);
  background: linear-gradient(to top, rgb(0 0 0 / 85%), #424242);
  padding: 0px 5px;
  border-radius: 20px;
  border: none;
}
.btn_freecredit {
  width: 75px;
  height: 35px;
  border-radius: 20px;
  background: linear-gradient(180deg, #ffe66f, #fffb8f 31.25%, #debc50 70.31%, #fde26b);
}
.ul_number_list {
  list-style: none;
  display: flex;
  padding: 0px;
}
.withdraw_rules {
  margin: 32px 0px 0px 0px;
  text-align: center;
}
.withdraw_rules button {
  background: none;
  border: none;
}
.withdraw_rules button p {
  color: #fff;
  text-align: center;
}
.btn_del_data_lotto{
  background-color: #4f4f4f !important;
}
.img_width100 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  
// }
.bg_img_style {
  // background: #191e32;
  background: transparent linear-gradient(#1a1500 0%, #0d0b01 100%) 0% 0% no-repeat padding-box !important;
  touch-action: manipulation;
  position: absolute;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-position: center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
}
.container_loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.txt_loader h1 {
  color: #f2d638;
  font-size: 16px;
  font-weight: 400;
  margin: 2px auto;
}
.loading {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .line_box {
  padding: 2px;
  width: 226.3083451202px;
  // height: 20px;
  border: 2px solid #ffc107;
  border-radius: 20px;
}
.loading .line_box .line {
  width: 226.3083451202px;
  height: 16px;
  color: #f2d638;
  border: 2px solid;
  position: relative;
  filter: drop-shadow(0 0 10px #f2d638);

  border-radius: 20px;
  background: #f2d638;
  animation: loading 2s forwards cubic-bezier(0,0,0,0);
}
.div_box_history_deposit {
  // background: RED;
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}
.div_box_history_deposit_sec1 {
  // background: hsla(0,0%,100%,.902);
  background: linear-gradient(180deg, #070D1B 0, #091523);
  padding: 10px 15px;
  // border: 2px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  margin: 0 auto;
}
.icon-game-type {
  width: auto;
  height: auto;
}
.border-provider {
  border: 3px solid red;
  border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
}
.card-game:hover {
  transform: scale(1.1);
}
.font-gold {
  color: #ffd700;
}
.box-page {
  background: #191e32;
  margin-top: 5.5rem !important;
}
.card-personal {
  background-color: transparent;
  border: 3px solid #fcfcfc;
  height: 300px;
  border-radius: 50%;
  width: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #fcfcfc;
  // box-shadow: 0 0 5px #ffffff, 0 0 0px #a9dfdf, 0 0 7px #abf5f5;
}
@media only screen and (max-width: 768px) {
  .box-page {
    margin-top: 5.5rem !important;
  }
  .icon-bottom-navbar {
    display: block;
    // animation: wiggle 2s linear infinite;
  }
  .div-container {
    width: 90%;
  }
  .container-affiliate {
    margin-top: 2rem;
  }
  .container-promotion-detail {
    margin-top: 2rem;
  }
  .image-promotion-detail {
    width: 80%;
  }
  .img-affiliate {
    width: 100%;
  }
  .card-reward {
    width: 100%;
  }
  .col-20percent {
    display: block;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .inpu-query {
    width: 100%;
  }
  .bottom-navbar {
    display: block;
  }
  .btn_numpad {
    min-width: 50px !important;
  }
  .div_content_select_lotto{
    padding-left: 5px !important;
  }
  .div_delete_number {
    text-align: end;
  }
  .btn_delete_number {
    background-color: #F8D364 !important;
    min-width: 0px !important;
    width: 95% !important;
  }
  .div_delete_data_lotto{
    text-align: end;
  }
  .div_del_data_lotto{
    text-align: end;
    padding: 0px;
  }
  .btn_del_data_lotto{
    min-width: 0px !important;
    width: 95% !important;
    padding: 4px !important;
  }
  .btn_delete_data_lotto{
    min-width: 0px !important;
    width: 95% !important;
  }
  .img_ccontact_24h {
    width: 100px;
  }
  .contact_24h {
    font-size: 16px;
  }
  .modal_card_withdraw {
    font-size: 14px;
  }
  .btn-summit {
    width: 90% !important;
    font-size: 14px;
  }
  .modal-content {
    width: 95%;
    margin: 0 auto;
  }
  .info_web {
    font-size: 10px;
  }
  .div_affiliate_detail {
    padding: 15px;
    border-radius: 7px;
  }
  .container-affiliate-detail {
    font-size: 16px;
    text-indent: 25px;
  }
  .mt-3_5rem {
    margin-top: 3.3rem !important;
  }
  .item-withdrawHistory {
    font-size: 10px;
  }
  .container-footer {
    padding-bottom: 6rem;
  }
  .div-container {
    width: 100%;
  }
  .div-container-affiliate {
    width: 100%;
    margin-top: 35px;
  }
  .container-activity-guide {
    margin-top: 2rem;
  }
  .image-tab-game {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .container-affiliate {
    margin-top: 2rem;
    padding: 20px;
  }
  .img-charactor {
    width: 90%;
  }
  .container-promotion-detail {
    margin-top: 2rem;
  }
  .div-container-promotion {
    width: 100%;
    margin-top: 35px;
  }
  .image-promotion-detail {
    width: 100%;
  }
  .img-affiliate {
    width: 100%;
  }
  .text-reward-name {
    font-size: 14px;
  }
  .text-reward-info {
    font-size: 12px;
  }
  .container-reward {
    margin-top: 2rem;
  }
  .text-details {
    font-size: 12px;
  }
  .card-reward {
    width: 100%;
  }
  .col-20percent {
    display: block;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .inpu-query {
    width: 100%;
  }
  .box-content {
    padding: 0 2%;
  }
  .border-table {
    font-size: 12px;
  }
  .tab-games {
    background: url("../image/bg-mobile.jpg");
  }
  .tab_article {
    // background: url("../image/bg-mobile.jpg");
  }
  .btn-history {
    width: 100%;
  }
  .bottom-navbar {
    display: block;
    font-size: 5px;
  }
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

// .boder-main-icon {
//   border: 3px solid red;
//   border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
// }