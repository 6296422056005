.btn-copy {
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border-radius: 25px;
  color: #fcfcfc;
  border: transparent;
  width: 100%;
  background: #6441A5;
  background: -webkit-linear-gradient(to right, #2a0845, #6441A5);
  background: linear-gradient(to right, #2a0845, #6441A5);
}
.btn-copy:hover {
  color: #b58c4a;
}
.btn-upload {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  height: 45px;
  border-radius: 25px;
  color: #fcfcfc;
  border: transparent;
  width: 70%;
  background: #9147ff;
}
.btn-verify-close {
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  height: 45px;
  border-radius: 25px;
  color: #000000;
  border: transparent;
  width: 70%;
  background: #ada996;
  background: -webkit-linear-gradient(to right,
      #eaeaea,
      #dbdbdb,
      #f2f2f2,
      #ada996);
  background: linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996);
}
.upload-image {
  border-style: dotted;
  border-color: #D989B5;
  padding: 10% 5%;
}
.image-slip {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 5%;
  border-radius: 5px;
}
.custom-modal-statement>div> {
  background: linear-gradient(180deg, #070D1B 0, #091523);
  box-shadow: 0 0 5px #ffffff, 0 0 30px #b58c4a, 0 0 30px #b58c4a;
  border: none;
}
.custom-modal-statement>div>.modal-content>.modal-body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: linear-gradient(180deg, #070D1B 0, #091523);
  padding: 0 0;
}
.custom-modal-statement>div>.modal-content>.modal-header {
  border-bottom: #b58c4a solid;
  color: #fcfcfc;
}
.color-white {
  color: #fcfcfc;
}
.alert-danger-custom1 {
  color: #ff9300 !important;
  background-color: #fff4cf !important;
  border-color: transparent !important;
  border-radius: 12px !important;
  font-size: 12px !important;
}
.v-card {
  // background-image: url(../logo/v-card3.jpg);
  background-size: cover;
  max-width: 100% !important;
  // box-shadow: 0 0 5px #ffffff, 0 0 5px #a3f4f4, 0 0 5px #abf5f5;
  box-shadow: #fff 0px 0px 5px, #b58c4a 0px 0px 5px, #b58c4a 0px 0px 10px;
  border-radius: 0.5rem !important;
  background: linear-gradient(180deg, #070D1B 0, #091523);
}
.card_personal {
  margin-top: 3rem;
}
.div_logo_personal {
  margin-top: -6rem !important;
  text-align: center;
}
.img_logo_personal {
  height: 150px;
  width: 100%;
  object-fit: contain;
}
.card_account_personal {
  // box-shadow: 0 0 5px #ffffff, 0 0 5px #a3f4f4, 0 0 5px #abf5f5;
  box-shadow: #fff 0px 0px 5px, #b58c4a 0px 0px 5px, #b58c4a 0px 0px 10px;
  background: linear-gradient(180deg, #070D1B 0, #091523);
  padding: 0px;
}
.btn-withdraw {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  height: 40px;
  border-radius: 30px;
  color: #000000;
  border: transparent;
  width: 50%;
  background: linear-gradient(to right, #f9a722, #f3af40, #ffdf94, #f3af40, #f9a722);
}
.img_bank_personal {
  max-width: 60px;
}
.div_copy_deposit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div_img_deposit {
  align-items: center;
  display: flex;
  justify-content: center;
}
.div_container_deposit {
  border-radius: .5rem;
  padding: 2% 1%;
  box-shadow: 0 0 5px #fff2c0, 0 0 5px #000000, 0 0 5px #000000;
  background: linear-gradient(180deg, #000 0, #0c112c 100%), linear-gradient(0deg, #191f3a, #191f3a) !important;
}
.div_container_main_deposit {
  border-radius: 15px;
  margin: 3% 0% 5% 0%;
}
.div_withdraw_deopsit {
  font-size: 16px;
}
.header-slip {
  font-size: 18px;
  margin: 0px;
}
.card_modal_statment {

}
@media only screen and (max-width: 768px) {
  .div_withdraw_deopsit p{
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .div_container_main_deposit{
    margin: 3% 0% 8% 0%;
  }
  .div_copy_deposit {
    // display: flex;
    // align-items: center;
    margin-top: -2rem !important;
  }
  .div_copy_deposit p {
    font-size: 14px;
    margin-top: 5px;
  }
  .div_chang_pass {
    font-size: 14px;
  }
  .info_title_personal {
    font-size: 18px;
  }
  .img_bank_personal {
    max-width: 60px;
    margin-left: 15px;
  }
  .card_account_personal {
    font-size: 14px;
  }
  .card_personal {
    font-size: 14px;
  }
  .btn-upload {
    font-size: 14px;
    font-weight: bold;
    width: 80%;
  }
  .MuiOutlinedInput-input {
    font-size: 14px !important;
  }
  .btn-verify-close {
    font-size: 14px;
    font-weight: bold;
    width: 80%;
  }
}