.tab-custom2>.nav-item>.nav-link {
  color: #fcfcfc !important;
  font-size: 0.8rem !important;
  width: 100%;
  height: 100%;
  // background-color: #5c16c5;
}
.tab-custom2>.nav-item>#controlled-tab-example-tab-all.active>div {
  background-color: white !important;
}
.tab-custom2>.nav-item>.nav-link.active {
  border-color: #fcfcfc;
  background: #f1f6f5;
  border-radius: 20px;
  color: #fcfcfc;
}
.p_tab_games {
  font-weight: 600;
  margin: 5px 0px 5px 10px;
}
.nav-pills .nav-link {
  position: relative;
  padding: 1px 25px;
  margin: 0 25px;
}
.nav-pills .nav-link.active {
  .p_tab_games {
    color: #000;
  }
  background: linear-gradient(180deg, #070D1B 0, #091523);
  box-shadow: 0 0 5px #ffffff,
  0 0 10px #f8f9fa,
  0 0 15px #f3af40;
}
.nav-pills .nav-link:hover {
  border-radius: 3px;
  --border-size: 0.1rem;
  border: var(--border-size) solid transparent;
  background-image: linear-gradient(171deg, #070D1B, #091523 51.68%, #004463),
    linear-gradient(90deg, #ffffff, #ffffff 31.53%, #ffffff 64.87%, #ffffff);
  border-image: conic-gradient(from var(--angle), #d53e3300 0deg 90deg, #ffffff 90deg 180deg, #377af500 180deg 270deg, #ffffff 270deg 360deg) 1 stretch;
}
/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  .nav-pills .nav-link {
    animation: rotate 3s linear infinite, opacityChange 3s infinite alternate;
  }
}
.div_tab_games {
  font-size: 16px;
  text-align: center;
}
.tab-custom2>.nav-item>.nav-link:hover {
  border-color: none !important;
  // border-radius: 10px;
}
.tab-custom2 {
  border-bottom: 0px !important;
  background: #151515;
}
.tab-custom2>.nav-item>.nav-link>span>p {
  font-size: 1rem;
}
.tab-custom2>.nav-item>.nav-link>span>div {
  min-height: 64px;
}
.tab-custom2>.nav-item>.nav-link:hover>span>div>.imgeffect {
  filter: invert(78%) sepia(67%) saturate(375%) hue-rotate(360deg) brightness(302%) contrast(102%);
}
.tab-custom2>.nav-item>.nav-link.active>span>div>.imgeffect {
  filter: invert(78%) sepia(67%) saturate(375%) hue-rotate(360deg) brightness(302%) contrast(102%);
}
.wrap {
  width: 100%;
  display: flex;
}
.wrap span {
  color: #fcfcfc;
  align-self: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2% 8%;
  width: 100%;
  text-align: center;
  // border-bottom-left-radius: 2px;
  // border-bottom-right-radius: 9px;
  opacity: 0.9;
}
.img_game {
  width: 100%;
  height: 180px;
  // border-radius: 10px;
}
.header-list-game {
  color: #fcfcfc;
}
.display_none {
  display: none;
}
.display_block {
  display: block;
}
.image-provider:hover {
  transform: scale(1.1);
}
.text-provider {
  font-size: 26px;
}
.card-jackpot {
  background-color: #091523;
  color: #fcfcfc;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 4% 1%;
  border-radius: 12px;
  font-size: 20px;
  border: 5px solid red;
  border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
}
.tab-name {
  font-size: 24px;
}
.nav_link_menu_games {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  width: 65%;
  text-align: center;
  margin: 0 auto;
}
.container_main_games {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0px !important;
  position: relative;
  min-height: 75vh;
  max-width: 100% !important;
  width: 90% !important;
}
.box-wrapper {
  position: absolute;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
}
.inner-wrapper {
  position: absolute;
  width: 100%;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.wheel-frame {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.play-btn {
  height: 98%;
  background: transparent !important;
  color: white;
  border: 0px !important;
  position: absolute;
  z-index: 10;
  width: 98%;
  top: 0px;
  border-radius: 300px;
}
.play-btn-transparent {
  height: 430px;
  background: transparent !important;
  color: transparent !important;
  border: 0px;
  position: absolute;
  z-index: 6;
  width: 430px;
  top: 220px;
  border-radius: 300px;
}
.center-btn {
  position: absolute;
  z-index: 5;
  width: 6rem;
}
.gTLZXx {
  display: none !important;
}
.btn-turn {
  font-size: 16px;
  height: 40px;
  border-radius: 10px;
  color: #fcfcfc;
  border: transparent;
  width: 160px;
  // background: #9147ff;
}
.btn-back {
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  height: 35px;
  border-radius: 10px;
  color: #000000;
  border: transparent;
  background: #ada996;
  background: -webkit-linear-gradient(to right,
      #eaeaea,
      #dbdbdb,
      #f2f2f2,
      #ada996);
  background: linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996);
}
.div_container_main_games {
  padding: 0px;
}
.div_head_box_games {
    display: flex;
    align-items: center;
    // background-color: #fff;
    // padding: 10px;
    // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-radius: 0.25rem !important;
}
.tab_games {
  // background: url("../image/BG.png");
  background-size: cover;
  // border-top: 2px solid red;
  // border-bottom: 2px solid red;
  border-left-width: 0;
  border-right-width: 0;
  // border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C) 1 stretch;
  padding: 7px 0;
}
.div_title_games_provider {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.div_title_games_provider p {
  font-weight: bold;
  margin: 0 auto;
  font-size: 25px;
  color: #fff;
}
.games_topic {
  display: flex;
  justify-content: center;
}
.games_topic img{
  height: 105px;
  overflow: hidden;
}
.games_topic p {
  // font-size: 1.9rem;
  // font-weight: 600;
  // color: #fff;
  -moz-text-align-last: center;
  text-align-last: center;
  position: absolute;
  align-self: center;
}
.search_icon {
  position: absolute;
  margin: 7px 10px;
  color: #817752;
}
.search_header {
  padding-inline-start: 40px;
  border-radius: 0.5rem;
  color: #fff !important;
  background: transparent !important;
  border: #343a40 2px solid !important;
  padding: 10px 40px !important;
}
.search_header:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #000 inset !important;
  -webkit-text-fill-color: #b1b1b1;
}
.search_header::placeholder {
  opacity: 0.5 !important;
  color: rgb(180, 180, 180) !important;
}
.div_grid_container_games_list {
  display: flex;
  align-items: center;
}
.div_games_list {
  text-align: center;
  padding: 10px;
}
.img_games_list {
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: 1s ease;
  position: relative;
  z-index: 1;
  border-radius: 2rem;
}
.img_games_list:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  transition: 0.8s ease;
}
.txt_games_list {
  position: relative;
  padding: 0px;
  // background: linear-gradient(90deg,#04042e,#185e93,#04042e);
  border-color: #ffe1a6;
  color: #ffe1a6;
  margin: 0 auto;
  margin-top: 2px;
  font-size: 14px;
  // margin-left: 15px;
  // margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 0.25rem;
  width: 100%;
}
.div_tab_game_select {
  margin-top: -0.5rem !important;
  // background-color: #0000002e;
  // padding: 0px 15px;
  border-radius: 0.5rem;
  // border: none !important;
  // border-radius: 0.5rem !important;
}
.div_tab_game_select_sec {
  transition: 1s ease;
}
.div_tab_game_select_sec:hover {
  .icon_game_type {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    transition: 1s ease;
  }
}
.div_tab_game_select_sec button {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
  padding: 15px;

  // background: linear-gradient(180deg, #000 0, #0c112c 100%);
  // border: 1px solid #3b3b3e;
  border-radius: 10px;
}
.div_provider_game_select {
  padding: 0px 0px 0px 15px;
}
.card_game {
  transition: 1s ease;
}
.card_game:hover{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 0.8s ease;
}
.border_provider {
  border-radius: 0.25rem;
}
@media only screen and (max-width: 768px) {
  .container_main_games {
    width: 95% !important;
  }
  .div_provider_game_select {
    padding: 0px 0px 0px 5px;
  }
  .p_tab_games {
    font-size: 14px;
    margin: 5px auto;
  }
  .div_tab_game_select_sec button {
    padding: 0px;
    display: block;
  }
  .div_tab_game_select {
    padding: 0px 5px 0px 0px;
  }
  .txt_games_list {
    font-size: 10px;
    padding: 0px;
    height: auto;
    width: 100%;
    margin: 0 auto !important;
    background: transparent;
  }
  .img_games_list {
    height: auto;
    width: 100px;
    border-radius: 1.3rem;
  }
  .div_games_list {
    padding: 5px 0px;
  }
  .games_topic img{
    height: 85px;
    overflow: hidden;
    color: #fff;
    margin-bottom: 10px;
  }
  .div_title_games_provider {
    margin-bottom: 1rem;
  }
  .nav_link_menu_games {
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
    padding: 5px;
  }
  .div_tab_games {
    font-size: 14px;
  }
  .nav-pills .nav-link {
    padding: 1px 16px;
    margin: 0 1px;
  }
  .text-provider {
    font-size: 17px;
  }
  .card-jackpot {
    font-size: 14px;
    width: 100%;
  }
  .tab-name {
    font-size: 16px;
  }
}