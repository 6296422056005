.div_container_main_article {
    flex-direction: column;
    // display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70%;
    color: #ffffff;
    margin: 10.5rem auto;
    background: linear-gradient(180deg, #070D1B 0, #091523);
    border-radius: 8px;
}
.div_body_main_article {
    border-radius: 5px;
    box-shadow: 0 0 5px #ffffff, 0 0 0px #fcfcfc, 0 0 7px #ffc107;
    // box-shadow: 0 0 5px #ffffff, 0 0 0px #a3f4f4, 0 0 7px #abf5f5;
}
.div_img_pro {
    padding: 15px;
}
.contact_image_pro {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
}
.contact_image_pro:hover {
    transform: scale(1.1);
}
.p_article_main {
    font-size: 16px;
    margin-top: 10px;
}
#div_coupon1 {
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: rgba(73, 73, 73, 0.5);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 5px;
}
@media only screen and (max-width: 768px) {
    .img_tab_article {
        width: 40px;
    }
    .div_container_main_article {
        margin: 10rem auto;
        width: 95%;
    }
    .div_body_main_article {
    }
    .contact_image_pro {
        width: 100%;
        height: auto;
    }
    .p_article_main {
        font-size: 14px;
    }
}