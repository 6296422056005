.contact_img_interesting {
    width: 100%;
    height: auto;
    border-radius: 5px;
}
.interesting_article p {
    text-align: left;
    font-size: 16px;
    text-indent: 0px;
    margin: 8px 0px 30px 0px;
}
.interesting_article h3 {
    color: #ffb600;
    text-align: center;
    font-size: 20px;
    margin: 25px 0px 15px 0px;
}
.div_img_article_new {
    margin: 0 auto;
    padding: 10px;
}
@media only screen and (max-width: 768px) {
    .interesting_article h3 {
        font-size: 18px;
    }
    .contact_img_interesting {
        width: 330px;
        height: 180px;
    }
    .div_img_article_new {
        margin: 0 auto;
        padding: 0px 0px 10px 0px;
    }
}