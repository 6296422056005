@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: Kanit, sans-serif !important;
}
@import "custom-bar";
@import "game";
@import "footer";
@import "statement";
@import "custom-promotion";
@import "custom";
@import "custom-article";
@import "custom-article-header";
@import "custom-article-interesting";
@import "custom-lotto";
@import "custom-animetion";
@import "custom-deposit";
@import "custom_modal";

// @font-face {
//   font-family: "Noto Sans Lao', sans-serif";
//   src: local("Noto Sans Lao', sans-serif"), url(../font/NotoSansLao-VariableFont_wdth,wght.ttf);
// }

// * {
//   font-family: "Noto Sans Lao', sans-serif" !important;
// }

html,
body {
  overflow: auto !important;
  background-color: #04040c !important;
  // height: 100%;
  margin: 0;
}

// body>#root>div {
//   height: 100vh;
// }