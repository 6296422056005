@keyframes laodingCicle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    /*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
    // text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500,
    //   0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff;
  }
}
@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    /*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
    // text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500,
    //   0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff;
  }
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes up_down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px)
  }
  50% {
    transform: translateX(80px)
  }
  100% {
    transform: translateX(0px)
  }
}
@keyframes letter-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  4% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  19% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  23% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  80% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
  87% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(22, 242, 59, 1);
  }
}
.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  to {
    background-position: 0% 50%
  }
}
@keyframes zoom {
  0% {
    transform: scale(.95) translateY(-30px);
  }
  50% {
    transform: scale(1.05) translateY(-30px);
  }
  100% {
    transform: scale(.95) translateY(-30px);
  }
}
@-webkit-keyframes mover {
  0% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}
@keyframes x-hamburger-wave {
  0% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes spin {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
@keyframes brightness {
  0%,
  50%,
  100% {
    filter: blur(0px) brightness(120%) drop-shadow(0 0 2.5px rgba(255, 255, 255, .1)) drop-shadow(0 0 5px rgba(255, 255, 255, .075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, .045)) drop-shadow(0 0 10px rgba(255, 255, 255, .025));
  }
  25%,
  75% {
    filter: blur(1px) brightness(50%) drop-shadow(0 0 2.5px rgba(255, 255, 255, .1)) drop-shadow(0 0 5px rgba(255, 255, 255, .075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, .045)) drop-shadow(0 0 10px rgba(255, 255, 255, .025));
  }
}
@keyframes shine {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
@keyframes card {
  0% {
    translate: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes blink_game {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    translate: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes top-bottom {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translate(0);
  }
  100% {
    transform: translateY(5px);
  }
}
@keyframes square-in-top-right {
  0% {
    -webkit-clipPath: inset(100% 100% 0 0);
    clipPath: inset(100% 100% 0 0);
  }
  100% {
    -webkit-clipPath: inset(0 0 0 0);
    clipPath: inset(0 0 0 0);
  }
}
@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
@keyframes tada {
  0% {
    transform: scaleZ(1);
  }
  10%,
  20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scaleZ(1);
  }
}
@keyframes rotate3D {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}