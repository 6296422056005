.div_winglang_wing_bon {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.box_content {
    // margin-bottom: 15px !important;
}
.btn_3bon_lang_tod_1 {
    width: 100%;
    border-radius: 0.25rem !important;
    border: 1px solid #6c757d !important;
}
.btn_front_black {
    min-width: 0px !important;
    width: 100%;
    border-radius: 2px !important;
    border: 1px solid #6c757d !important;
    padding: 5px 8px !important;
}
.btn_huay_select {
    border: #FFCC00 1px solid;
    border-radius: 0.2rem;
    background-color: #f3af40 !important;
    color: #fff;
    width: 100%;
    padding: 0px;
}
.div_result_two{
    justify-content: center;
    margin-top: 9px !important;
    border-top: 1px solid #dee2e6;
}
// .label_front_black {
//     padding: 0px 2px 0px 0px;
// }
.btn_3bon_lang_tod {
    border: #FFCC00 1px solid;
    border-radius: 0.2rem;
    background-color: #fff !important;
    color: #f3af40;
    width: 100%;
    padding: 0px;
}
.list_selected {
    font-size: 14px !important;
    font-weight: bold !important;
}
.txt_head_list {
    font-size: 16px;
    color: #fff;
    background: #252e3aad;
    border-radius: .25rem;
}
.txt_list_bet {
    font-size: 16px;
    color: #fff;
}
.txt_head_list2 {
    font-size: 16px;
    color: #e1e1e1;
}
.txt_list_lotto {
    text-align: center;
    font-size: 16px;
    color: #fff;
}
.container_lottobuy {
    margin-top: 6rem;
    margin-bottom: 5rem;
    padding: 0px !important;
    min-height: 75vh;
}
.container_lotto {
    margin-top: 6rem;
    margin-bottom: 5rem;
    padding: 0px !important;
    position: relative;
    min-height: 75vh;
}
.div_chk_btn {
    border: 1px solid red;
    border-radius: 3px;
}
.btn_chk1_btn_false {
    border: 1px solid #28a745  !important;
    border-radius: 3px !important;
}
.btn_chk2_btn_false {
    border: 1px solid #007bff  !important;
    border-radius: 3px !important;
}
.btn_chk3_btn_false {
    border: 1px solid #dc3545 !important;
    border-radius: 3px !important;
}
.btn_name_num3_active {
    background-color: #00000080  !important;
    // border-color: #dc3545 !important;
    // outline: 1px dashed black !important;
    // outline-offset: 2px !important;
}
.div_rate_lotto_active {
    background: #ffc107 !important;
    color: #4d4d4d;
    font-size: 12px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.div_select3_lotto {
    background-color: #ffd8d8 !important;
    color: #000;
    font-size: 12px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.div_select2_lotto {
    background-color: #eaf4ff !important;
    color: #000;
    font-size: 12px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.div_select1_lotto {
    background-color: #f3fff3 !important;
    color: #000;
    font-size: 12px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.btn_chk_btn {
    outline: 1px dashed black !important;
    outline-offset: 2px !important;
}
.btn_red_bon_nt_active {
    background-color: #dc3545 !important;
    color: #fff !important;
}
.btn_outline_blue_lang {
    background-color: #164f8d !important;
    border-color: #007bff !important;
    outline: 1px dashed black !important;
    outline-offset: 2px !important;
}
.btn_blue_lang_nt_active {
    background-color: #007bff !important;
    color: #fff !important;
}
.btn_outline_green_wing {
    background-color: #276d37 !important;
    border-color: #28a745 !important;
    outline: 1px dashed black !important;
    outline-offset: 2px !important;
}
.btn_green_wing_nt_active {
    background-color: #28a745 !important;
    color: #fff !important;
}
.test11 {
    background-color: red;
}
.txt_name_huay_round {
    margin: 0px 7px 0px !important;
}
.timer_lotto_buy {
    background: rgba(0, 0, 0, .1);
    // border: #ccc 1px solid;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
}
.rules_lotto_buy {
    background-color: #1d3765 !important;
    border: none !important;
    padding: 10px !important;
    border-radius: 2px !important;
    cursor: pointer !important;
    color: #fff !important;
}
.lotto_poye_refresh {
    background-color: #eee !important;
    border: none !important;
    padding: 0px !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    background-color: #f3af40 !important;
}
.lotto_poye_refresh span{
    font-size: 18px;
    font-weight: 500;
}
.icon_lotto {
    width: 40px;
    height: 25px;
    border: none;
    margin: 5px !important;
    border-radius: 2px;
}
.icon_lotto_result {
    width: 30px;
    height: 30px;
    border: none;
    margin-left: 5px !important;
    border-radius: 50%;
}
.icon_lottoV2 {
    width: 40px;
    height: 25px;
    border: none;
    margin: 5px !important;
    border-radius: 2px;
}
.close_huay {
    margin-bottom: 4px;
    margin-right: 4px !important;
    font-size: 12px !important;
    color: #fff !important;
}
.close_huay span {
    background: #343a40 !important;
    border-radius: 0.25rem !important;
    padding: 0px 4px;
}
.close_huay_real {
    margin-bottom: 4px;
    margin-right: 4px !important;
    font-size: 12px !important;
    color: #fff !important;
    // height: 69px !important;
}
.close_huay_real span {
    background: #343a40 !important;
    border-radius: 0.25rem !important;
    padding: 0px 4px;
}
.lotto_poye {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.div_poye_wait_scc {
    border-left: 1px solid #dee2e6;
    color: #000;
    text-align: center;
    padding: 10px;
    text-wrap: nowrap;
}
.div_poye_winlose {
    border-left: 1px solid #dee2e6;
    color: #000;
    text-align: center;
    padding: 10px;
    text-wrap: nowrap;
    & h5 {
        color: #bd8429;
    }
}
.h_sum_poye {
    margin: 0 auto;
}
.h_sum_poye_details {
    margin: 0 auto;
    color: #377de5;
}
.h_num_poye_scc {
    margin: 0 auto;
    color: #10b368;
}
.h_num_poye_wait {
    margin: 0 auto;
    color: #377de5;
}
.btn_primary {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
    height: 100%;
}
// .btn_primary {
//     color: #fff;
//     background-color: #007bff;
//     border-color: #007bff;
// }
.btn_warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    height: 100%;
}
.btn_success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    height: 100%;
}
.btn_secondary {
    color: #fff;
    background-color: #ff4d3b;
    border-color: #ff4d3b;
    height: 100%;
}
.btn_poye {
    padding: 7.8px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: all 0.5s;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 99%;
    // margin-top: 2px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}
.btn_poye.active,
.btn_poye:hover {
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-top: rgba(255, 255, 255, 0.5) 5px solid !important;
    transform: all 0.5s;
    color: #fff;
}
.bg_blue {
    background-color: #005cbf;
}
.color_blue {
    color: #005cbf;
}
.bg_yellow {
    background-color: #ffc107;
}
.bg_green {
    background-color: #28a745;
}
.bg_gray {
    background-color: #6c757d;
}
.div_lotto_poye_all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    border: 1px solid #939393;
}
.spn_lotto_poye_all {
    padding: 5px 8px;
    font-size: 12px;
}
.spn_lotto_poye_all span {
    font-size: 14px;
}
.spn_lotto_poye_wait {
    background-color: #ffc107;
    padding: 6.5px 5px;
    border-top-right-radius: 4px 4px;
    font-size: 12px;
}
.spn_lotto_poye_scc {
    color: #fff;
    background-color: #28a745;
    padding: 6.5px 5px;
    border-top-right-radius: 4px 4px;
    font-size: 12px;
}
.spn_lotto_poye {
    color: #fff;
    padding: 6.5px 5px;
    border-top-right-radius: 4px 4px;
    font-size: 12px;
}
.card_lotto_poye_all {
    border-left: 1px solid #939393 !important;
    border-right: 1px solid #939393 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.card_lotto_poye_all_v2 {
    border-left: 1px solid #939393 !important;
    border-right: 1px solid #939393 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom: 1px solid #939393 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.div_lotto_poye_bet_winlose {
    border-left: 1px solid #dee2e6 !important;
}
.flex_space_between {
    display: flex;
    justify-content: space-between;
}
.div_des_lotto_poye_all {
    // border-left: 1px solid #dee2e6;
    color: #000;
    text-align: center;
    padding: 10px;
    font-size: 12px;
}
.div_des_lotto_poye_all div p {
    margin: 4px 0px;
}
.div_lotto_poye_con {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e7e7e7 !important;
}
.lotto_number_selected {
    background-color: #b7b7b773;
    border-radius: 0.25rem;
    text-align: center;
}
.lotto_number_selected p {
    margin: 0 auto;
}
.lotto_poye_all_number {
    font-size: 14px;
}
.lotto_poye_date {
    font-size: 14px;
}
.btn_handle_des_lotto_poye:hover {
    color: #6C757D;
}
.btn_box_lotto_container_result {
    color: #fff !important;
    font-size: 16px !important;
    background-color: #0d6efd !important;
    height: 33px;
    font-size: 14px !important;
}
.btn_poye_lotto {
    border: 1px solid #e9dbdb !important;
    box-shadow: inset 0 0px 4px #000;
    height: 33px;
    font-size: 14px;
    margin-right: 15px !important;
}
.btn_poye_lotto:hover {
    box-shadow: inset 0 0px 4px #bbf00d;
}
.btn_back_poye_lotto {
    border: 1px solid #dd0d0d !important;
    /* box-shadow: inset 0 0px 1px #f00; */
    color: #dd0d0d !important;
}
.btn_back_poye_lotto:hover {
    box-shadow: inset 0 0px 4px #dd0d0d;
}
.lotto_num_1 {
    font-size: 26px;
    color: #007BFF;
    font-weight: bold;
}
.border_bt_1 {
    border-bottom: 1px solid #e7e7e7 !important;
}
.small_lotto_date_time_buy {
    display: flex;
    align-items: center;
    justify-content: end;
}
.div_name_lottobuy {
    background-color: #dc3545;
}
.div_container_lotto_results {
    width: 75%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 2rem;
}
.div_content_lotto_results {
    background: transparent linear-gradient(180deg, #FDFEF9 0%, #FEE1FD 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin: 0 auto !important;
}
.result_lotto_home_tab1 {
    // position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    // background-color: red;
}
.result_lotto_home_tab2 {
    // background-color: rgb(201, 201, 255);
    min-height: 1px;
    padding-right: 30px !important;
    padding-left: 30px !important;
    text-align: center;
}
.bg-topic-countdown-th-word {
    // background-color: #fff;
    background-color: #1A4EB2;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 8px;
    color: white;
    font-weight: 100;
}
.flag-on-top-lotto {
    height: 25px;
    width: auto;
    border-radius: 50%;
    padding: 1px;
}
.flag_on_top_lotto {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    padding: 1px;
}
.spn_flag_on_top_lotto {
    margin-left: 5px;
    font-size: 16px;
}
.div_countdown {
    padding: 1rem 3rem;
}
.div_countdown2 {
    display: flex;
    justify-content: center;
    width: 100%;
}
.bg-countdown-th {
    background: #000 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;
    padding: 8px 8px 3px 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
}
.bg_countdown_timeclose{
    background: #000 0% 0% no-repeat padding-box;
    border: gray 3px solid;
    padding: 8px 8px 3px 8px;
    color: gray;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
}
.bg_countdown_th2 {
    background: #000 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;
    padding: 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    width: 25%;
    height: 75px;
}
.bg_countdown_th2_timeclose {
    background: #000 0% 0% no-repeat padding-box;
    border: gray 3px solid;
    padding: 8px;
    color: gray;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    width: 25%;
    height: 75px;
}
.num_countdown {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.num_countdown2 {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.day_countdown {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
}
.txt_countdown_timeclose {
    color: gray;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
}
.v_close {
    background-color: gray;
    height: 21px;
    margin: 0 auto;
    width: 2px;
}
.day_countdown2 {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
}
.ulineCountdown {
    border-top: 3px solid #fdf689;
    width: 10px;
    align-self: center;
}
.ulineCountdown_timeclose {
    border-top: 3px solid gray;
    width: 10px;
    align-self: center;
}
.bg-countdown-hour {
    background: #000 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;
    padding: 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 1.5rem;
}
.bg_countdown_hour2 {
    background: #000 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;
    padding: 8px 8px 3px 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    width: 25%;
    height: 75px;
}
.bg-countdown-minute {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;
    padding: 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 1.5rem;
}
.bg_countdown_minute2 {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    border: #fdf689 3px solid;    
    padding: 8px 8px 3px 8px;
    color: #fdf689;
    font-weight: bold;
    border-radius: 6px;
    overflow: hidden;
    width: 25%;
    height: 75px;
}
.bet_ruay {
    color: #C21328;
    font-weight: bold;
    margin-top: 5px;
}
.btn_login_lotto_results {
    background-color: #C21328 !important;
    color: white !important;
    width: 100%;
    font-size: 1.1rem !important;
    font-weight: bold !important;
    height: 100%;
    border-radius: 0.25rem !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.x1_naja {
    background-color: #20e719;
    background: transparent linear-gradient(180deg, #FEF9F9 0%, #E1EFFE 100%) 0% 0% no-repeat padding-box;
    overflow-y: scroll;
    height: 780px;
    padding: 10px 10px 10px 15px;
}
.bg-topic-countdown-th-word_v2 {
    // background-color: #fff;
    background-color: #1A4EB2;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 8px;
    color: white;
    font-weight: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.div_huay_thai_top {
    // text-align: center;
    margin: 20px auto 20px auto !important
}
.div_huay_thai_top_sec1 {
    color: black;
    font-weight: 100;
    text-align: center;
    font-size: 1rem;
    justify-content: space-evenly;
    background: #E1EFFE 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    padding: 12px;
    border-radius: 10px;
}
.text_huay_sec_1 {
    width: 50%;
    color: black;
    background: transparent linear-gradient(180deg, #C78D36 0%, #F4D751 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 3px;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
    // font-size: larger;
}
.text_huay_sec_2 {
    width: 50%;
    color: black;
    background: white;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 3px;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 16px;
    // font-size: larger;
}
.div_loop_huay_v1 {
    // background-color: #fff;
    background-color: #C21328;
    border: 1px solid #fdf689;
    border-radius: 10px;
    box-shadow: inset 0px 3px 6px #00000029;
    padding: 8px;
    color: white;
    font-weight: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.div_huay_thai_top_sec2 {
    padding: 0px 10px 0px 40px;
}
.div_huay_thai_sec1{
    display: flex;
    justify-content: end;
    font-size: 14px;
}
.div_huay_thai_sec2 {
    display: flex;
    justify-content: space-between;
}
.div_huay_thai_sec2_sec1 {
    display: flex;
    width: 50%;
}
.p_number_huay {
    width: 22%;
    text-align: center;
    font-size: 16px;
}
.result_huay {
    font-size: 16px;
}
.date_time_huay {
    font-size: 16px;
    margin: 0 auto;
}
.result_last {
    font-size: 16px;
    color: #C21328;
    font-weight: bold;
}
.p_cont_down_huay {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #C21328;
}
.div_name_huaythai_loop {
    background-color: #1A4EB2;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 8px;
    color: white;
    font-weight: 100;
    width: 25%;
    height: 75px;
    text-align: center;
    margin-right: 10px;
}
.div_name_huaythai_loop p {
    font-size: 16px;
    margin: 5px auto auto auto;
}
.div_conttainer_lotto {
    padding: 0px;
}
.box_lotto_container_1 {
    line-height: 20px;
    padding: 10px;
    background: linear-gradient(90deg, #2c3352 0, #0c112c 53.75%);
    border-radius: 5px 5px 0px 0px;
}
.btn_box_lotto_container_1 {
    color: #fff !important;
    font-size: 16px !important;
}
.title_huay_lotto {
    font-weight: bold;
    color: #283051;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0px 10px 10px 10px;
    font-size: 16px;
    font-size: 20px;
}
.title_line_group {
    font-weight: bold;
    color: #283051;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0px 10px 10px 10px;
    font-size: 20px;
    // border-left: 4px solid red;
}
.div_card_loop_huay_sec {
    margin-top: 0.5rem !important;
    padding: 3px 5px !important;
}
.div_card_loop_huay_result_sec {
    margin-top: 0.5rem !important;
    padding: 3px 5px !important;
}
.paper_lotto_buy {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}
.txt_huay {
    font-size: 20px !important;
}
.list_bet_stack {
    height: 100%;
    padding: 4px;
    border-radius: 4px;
    // background: #000;
    background: linear-gradient(90deg, #0c112c 0, #020512 53.75%);
    margin-left: 5px;
}
.btn_set_price {
    font-weight: bold !important;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 14px !important;
}
.condition_lotto {
    border-radius: 5px;
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    padding: 5px 10px;
}
.btn_set_price2 {
    font-weight: bold !important;
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    font-size: 14px !important;
}
.btn_del_data_lotto{
    background-color: #4f4f4f !important;
    font-size: 13px !important;
}
.btn_delete_number {
    width: 100% !important;
}
.close_modal {
    display: flex;
    justify-content: end;
    color: #fff;
    cursor: pointer;
}
.close_modal:hover{
    color: #e2e2e2;
}
.txt_title_sec_reward {
    color: #414141;
    font-weight: bold;
}
// ================
.modal_get_creditfree .modal-content {
    background: #283051;
    box-shadow: none;
}
.modal_get_creditfree .modal-header {
    // background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_history_reward .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_history_reward .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_reward .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_reward .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_cashback .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_cashback .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_fixed_deposit .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_fixed_deposit .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_fixed_deposit .modal-body {
    padding: 0px;
}
.modal_popup_load .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_popup_load .modal-header {
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_popup_load .modal-body {
    padding: 0px;
}
.modal_register .modal-content {
    background: #fff;
    box-shadow: none;
}
.modal_register .modal-header{
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_login .modal-body{
    border: 1px solid #707070;
    border-radius: 20px !important;
    background-size: cover;
    box-shadow: none;

    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.modal_login .modal-content{
    box-shadow: none;
    border: 1px solid #707070;
    border-radius: 20px !important;
    background-size: cover;
}
.modal_lang .modal-dialog {
    text-align: center;
    align-items: center;
    justify-content: center;
}
.modal_lang .modal-content {
    width: 90%;
    background: #283051;
    border-bottom: none !important;
    box-shadow: none;
}
.modal_lang .modal-header {
    background: #283051;
    box-shadow: none;
    border-bottom: none;
}
.modal_wheel .modal-content{
    background: #fff;
    box-shadow: none;
}
.modal_wheel .modal-header{
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_promotion .modal-content{
    background: #fff;
    box-shadow: none;
}
.modal_promotion .modal-header{
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_role_modal .modal-content{
    background: #fff;
    box-shadow: none;
}
.modal_role_modal .modal-header{
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_role2_modal .modal-content{
    background: #fff;
    box-shadow: none;
}
.modal_role2_modal .modal-header{
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-bottom: none !important;
}
.modal_lottobuy_price {
    padding-right: 0px !important;
    overflow-y: hidden;
}
.modal_contentss {
    width: 60% !important;
    max-width: 100% !important;
    height: 100%;
    max-height: 100%;
    margin: 0 auto !important;
}
.modal_contentss .modal-content {
    box-shadow: none !important;
    border-radius: 5px !important;
    height: 100%;
    background: #f4f4f4 !important;
    border: none !important;
}
.txt_set_price {
    color: #fff;
}
.modal_contentss .modal-header {
    color: #fff;
    border-bottom: none;
    padding: 10px;
    background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 0px !important;
}
.modal_contentss .modal-header .btn-close {
    margin: 0 auto;
    padding-left: 20px;
    color: #fff !important;
}
.div_container_lotto_buy_price {
    padding: 0px !important;
    margin: 0 auto;
    width: 100% !important;
    max-width: 100% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 13rem !important;
}
.div_txt_list_bet{
    padding-left: 10px !important;
}
.div_des_rules p{
    font-size: 16px;
}
.modal_rules_footer button{
    padding: 5px;
    color: #fff;
    background-color: #0096e3;
    border-color: #0096e3;
    width: 100%;
    border: none;
    box-shadow: -1px 2px 5px black;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal_rules_footer2 {
    border-top: 1px solid #e9ecef;
    padding: 20px 0px 10px 0px;
}
.modal_rules_footer2 button{
    padding: 5px;
    color: #fff;
    background-color: #0096e3;
    border-color: #0096e3;
    width: 100%;
    border: none;
    box-shadow: -1px 2px 5px black;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.rule_lotto_buy {
    padding: 15px;
}
.rule_lotto_buy h2 {
    color: #fff;
    font-size: 30px !important;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: left !important;
}
.rule_lotto_buy p {
    margin: 0 auto 10px auto;
    // text-indent: 25px;
}
.close_icon_css {
    width: 15px !important;
    text-shadow: 0 1px 0 #fff;
    cursor: pointer;
    opacity: .5;
    color: #fff;
}
.close_icon_css:hover {
    opacity: 100%;
}
.title_popup_loadv2 {
    display: flex;
    margin: 0 auto !important;
    align-items: center;
    & span {
        font-size: 16px;
        margin: 0px 5px;
    }
}
.title_popup_load {
    margin: 0 auto !important;
}
.lotto_date_stock {
    margin: 0px 0px 7px;
    font-size: 12px !important;
    color: #fff !important;
    border-radius: 0.5rem !important;
}
.txt_name_huay {
    font-size: 16px !important;
    padding-right: 5px;
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0;
    -webkit-text-stroke: 0.1px rgb(156, 154, 154);
}
.txt_name_huay_buy {
    font-size: 16px !important;
    padding-right: 5px;
    color: #fff;
}
.txt_name_huay_renderer {
    font-size: 16px !important;
    padding-right: 5px;
    color: #919191;
    font-weight: bold !important;
}
.box_lotto_container {
    // background-image: url(../image/bg/main-bg.webp);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
    background: linear-gradient(90deg, #2c3352 0, #0c112c 53.75%);
}
.div_block_lotto {
    padding: 1rem .5rem;
    margin-top: 1rem;
    background: #f3f3f3;
    box-shadow: 0 0.125rem 0.25rem #00000013;
    border-radius: 0.25rem;
}
.div_block_line_group {
    padding: 1rem .5rem;
    background: #f3f3f3;
    box-shadow: 0 0.125rem 0.25rem #00000013;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    border-radius: 0.25rem;
}
.stack_closed_lotto {
    opacity: 50%;
}
.stack_lotto {
    height: 82px !important;
    background-size: cover !important;
}
.stack_lotto_result {
    height: 44px !important;
    align-items: center;
}
.card_loop_lotto_result {
    background-color: #007BFF !important;
    // height: 120px !important;
}
.card_loop_lotto_result_thai {
    background-color: #007BFF !important;
    // height: 120px !important;
}
.card_loop_lotto_result_abroad {
    background-color: #ff9100 !important;
    // height: 120px !important;
}
.card_loop_lotto_result_special_stocks {
    background-color: #007372 !important;
    // height: 120px !important;
}
.card_loop_lotto_result_share {
    background-color: #AA0061 !important;
    // height: 120px !important;
}
.card_loop_lotto_result_tgs {
    background-color: #019C0F !important;
}
.card_loop_lotto_result_aomsin {
    background-color: #A200A0 !important;
}
.card_loop_lotto_result_YeeKee1 {
    background-color: #ecdc18 !important;
}
.card_loop_lotto_result_YeeKee2 {
    background-color: #49b12c !important;
}
.card_loop_lotto_government {
    background-color: #007BFF !important;
    height: auto !important;
}
.stack_namelotto_flexend {
    display: flex !important;
    height: 100%;
    width: 100%;
    align-items: center;
}
.box_lotto_container_1_lotto {
    line-height: 20px;
    padding: 10px;
    background: linear-gradient(90deg, #2c3352 0, #0c112c 53.75%);
    border-radius: 5px 5px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.div_head_box_result {
    // display: flex;
    // align-items: center;
    // background: transparent linear-gradient(180deg, #0096E3 0%, #09b7ff 100%) 0% 0% no-repeat padding-box;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.div_head_box_result h4 {
    margin: 0px 0px 0px 3px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.div_head_box_result_sec {
    display: flex;
    align-items: center;
}
.div_head_box_result_tab_1 {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    background-color: #ffffffe6;
    box-shadow: 0 0.125rem 0.25rem #5b759b !important;
    margin-bottom: 1rem !important;
    border-radius: 0.25rem;
    height: 212px;
}
.div_result_twolang {
    text-align: center;
    & p {
       margin: 0 auto;
    }
}
.div_result_treebon {
    text-align: center;
    & p {
        margin: 0 auto;
    }
}
.txt_tile_lotto_result {
    display: flex;
    justify-content: end;
    align-items: center;
}
.div_date_lotto_result {
    font-size: 14px;
    align-items: center;
    display: flex;
    background-color: #343a40;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    padding: 0.25em 0.4em;
    border-radius: 0.25rem;
    color: #fff;
}
.grid_container_lotto_buy_price {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 60% !important;
    max-width: 100% !important;
    align-items: center;
    margin: 0 auto;
}
.donot_accept_modal {
    background-color: #000;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    & span {
        color: #fff;
        font-size: 18px;
    }
}
.div_isnot_list {
    text-align: center;
    width: 100%;
    padding: 15px;
}
.div_lotto_result_number {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #c7c7c7;
    color: #6c757d;
}
.div_lotto_result_number_government {
    display: flex;
    flex-wrap: wrap;
    // border-top: 1px solid #c7c7c7;
    color: #6c757d;
}
.font_bold {
    font-weight: bold;
}
.div_lotto_result_government {
    color: #6c757d;
    flex-grow: 1;
    flex-basis: 200;
    text-align: center;
    & p {
        border-bottom: 1px solid #c7c7c7;
        border-left: 1px solid #c7c7c7;
        border-right: 1px solid #c7c7c7;
        margin-bottom: 0px;
        padding: 5px 5px 8.6px 5px;
    }
}
.div_lotto_result_number_sec_1 {
    flex-grow: 1;
    flex-basis: 200;
    text-align: center;
    & p {
        border-bottom: 1px solid #c7c7c7;
        border-left: 1px solid #c7c7c7;
        border-right: 1px solid #c7c7c7;
        margin-bottom: 0px;
        padding: 5px 5px 8.6px 5px;
    }
}
.div_lotto_result_number_sec_2 {
    flex-grow: 1;
    flex-basis: 200;
    text-align: center;
    & p {
        border-bottom: 1px solid #c7c7c7;
        border-right: 1px solid #c7c7c7;
        margin-bottom: 0px;
        padding: 5px 5px 8.6px 5px;
    }
}
.div_24hr {
    color: #28a745;
    animation: letter-flicker 2s linear infinite;
}
.div_lotto_result_bydate {
    margin-left: 10px;
    & .react-datepicker__input-container {
        display: flex !important;
        align-items: center !important;
    }
}
.div_lotto_result_bydate>div>div>input {
    border: 1px solid #e3e3e3;
    border-radius: 0.5rem;
    font-size: 18px;
    color: #737373;
}
.div_result_isnot_lottothai {
    padding: 10px;
}
.div_result_date_isnot_lottothai {
    background: #e8e8e8;
    color: #000;
    border-radius: 6px;
    margin: 4px;
    padding: 6px 0px;
    font-weight: bold;
    text-align: center;
}
.div_lotto_card_title {
    background: #c7c8ca;
    color: #000;
    font-weight: bold;
    border-radius: 6px;
    margin: 0px 4px;
    padding: 6px 0px;
    text-align: center;
}
.txt_award {
    font-weight: bold;
    font-size: 2rem;
    color: #000000;
    background: #e8e8e8;
    margin: 4px;
    border-radius: 5px;
    text-align: center;
}
.txt_name_huay_result {
    font-size: 18px;
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
    padding: 4px 1px;
    /* margin-top: 1px; */
    color: #000;
    background-color: #fff;
    border-radius: 7px;
    margin-right: 8px !important;
    text-wrap: nowrap;
}
.div_head_box_results {
    padding: 0.5rem;
    border: rgba(255, 255, 255, 1) 2px solid;
    background: #ffffffe6;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.div_head_box_results_sec {
    display: flex;
    align-items: center;
    & h4 {
        margin: 0px 0px 0px 3px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }
}
.icon_lotto_results {
    position: relative;
    display: inline-block;
    width: 30px;
    line-height: 1em;
}
.spn_lotto_results {
    margin-left: 5px;
    font-weight: bold;
    font-size: 18px;
}
.table_head_lotto_results {
    background-color: #007aff;
    & th {
        color: #fff;
    }
}
.table_body_lotto_results {
    & th {
        color: #6c757d !important;
        font-size: 16px;
    }
    & td {
        color: #6c757d !important;
        font-size: 16px;
    }
}
.random_number {
    color: #fff !important;
    min-width: 50px !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}
.random_number_disabled {
    color: #ccc !important;
    min-width: 50px !important;
    background-color: #fff !important;
    border-color: 1px solid #0000001f !important;
}
.rule_lotto_des_real {
    & a  {
        padding: 0px;
        color: #007bff;
    }
}
.box_container_main {  
    line-height: 20px;
    padding: 10px;
    background: linear-gradient(90deg, #2c3352 0, #0c112c 53.75%);
    border-radius: 5px 5px 0px 0px;
  }
.box_container_main_games {
    line-height: 20px;
    padding: 10px;
    background: #f1f1f157;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
}
.container_main {
    margin-top: 6rem !important;
    margin-bottom: 5rem !important;
    padding: 0px !important;
    position: relative;
    min-height: 75vh;
}
.btn_box_container {
    color: #fff !important;
    font-size: 16px !important;
}
.div_img_reward_result_all {
    background: #efefef;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 0.5rem;
    & img {
        width: 30px;
        height: auto;
    }
    & h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0px 0px 0px 10px;
        color: #000;
    }
}
.div_date_reward_result_all {
    background: #efefef;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    & img {
        width: 30px;
        height: auto;
    }
    & h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0px 0px 0px 10px;
        color: #000;
    }
}
.box_reward_result_all {
    background-color: #fff;
    padding: 10px;
}
.stack_pagination_poye {
    padding: 5px 0px;
    border-radius: 0.5rem;
    background: #fbfbfb;
}
@media only screen and (max-width: 768px) {
    .div_lotto_result_bydate {
        margin-left: 15px;
    }
    .container_main {
        margin-top: 5.4rem !important;
        margin-bottom: 7rem !important;
    }
    .random_number {
        font-size: 12px !important;
        text-wrap: nowrap;
    }
    .random_number_disabled {
        font-size: 14px !important;
        text-wrap: nowrap;
    }
    .spn_lotto_results {
        font-size: 16px;
    }
    .stack_lotto_result {
        height: 37px;
    }
    .txt_award {
        font-size: 25px;
    }
    .div_lotto_card_title {
        font-size: 16px;
    }
    .div_result_date_isnot_lottothai {
        font-size: 16px;
    }
    .txt_name_huay_result {
        font-size: 18px;
    }
    .donot_accept_modal {
        & span {
            font-size: 16px;
        }
    }
    .title_popup_loadv2 {
        & span {
            font-size: 14px;
            margin: 0px 5px;
        }
    }
    .grid_container_lotto_buy_price {
        width: 100% !important;
    }
    .div_footer_2 {
        width: 111px;
    }
    .txt_name_huay_renderer {
        font-size: 14px !important;
        text-align: right;
    }
    .txt_name_huay {
        font-size: 14px !important;
        text-align: right;
    }
    .txt_name_huay_buy {
        font-size: 14px !important;
        text-align: right;
    }
    .lotto_date_stock {
        font-size: 11px !important;
    }
    .title_popup_load {
        font-size: 18px !important;
    }
    .txt_name_huay_round {
        font-size: 14px;
        padding-right: 0px !important;
    }
    .div_name_huaythai_loop p {
        font-size: 14px;
    }
    .rule_lotto_buy h2 {
        font-size: 20px !important;
        text-align: left;
    }
    .rule_lotto_buy p {
        // text-indent: 20px;
        font-size: 14px !important;
    }
    .div_des_rules p{
        font-size: 14px;
    }
    .div_des_rules li{
        font-size: 14px;
    }
    .div_des_rules strong{
        font-size: 14px;
    }
    .txt_list_bet {
        font-size: 12px !important;
        margin: 0 auto !important;
    }
    .modal_contentss {
        width: 100% !important;
        margin: 0 auto;
    }
    .modal_contentss .modal-content {
        width: 100%;
        box-shadow: none;
        border-radius: 0px;
    }
    .btn_delete_number {
        background-color: #F8D364 !important;
        min-width: 0px !important;
        width: 95% !important;
    }
    .btn_del_data_lotto{
        min-width: 0px !important;
        width: 95% !important;
        padding: 4px !important;
    }
    .txt_huay {
        font-size: 16px;
    }
    .div_sec_setting_m {
        display: flex;
        justify-content: end;
    }
    .div_name_huaythai_loop {
        width: 23%;
    }
    .p_cont_down_huay {
        font-size: 14px;
    }
    .result_last {
        font-size: 14px;
    }
    .x1_naja {
        padding: 10px;
    }
    .text_huay_sec_2 {
        font-size: 14px;
    }
    .p_number_huay {
        font-size: 14px;
    }
    .date_time_huay {
        font-size: 14px;
    }
    .result_huay {
        font-size: 14px;
    }
    .flag-on-top-lotto {
        height: 20px;
        width: auto;
        border-radius: 50%;
        padding: 1px;
    }
    .flag_on_top_lotto {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        padding: 1px;
    }
    .spn_flag_on_top_lotto {
        font-size: 14px;
    }
    .result_lotto_home_tab2 {
        display: none;
    }
    .div_huay_thai_top_sec2 {
        padding: 10px 10px 0px 0px;
    }
    // .label_front_black {
    //     padding: 0px 3px 0px 0px;
    // }
    .small_lotto_date_time_buy {
        display: block;
        align-items: center;
        justify-content: end;
    }
    .div_countdown {
        padding: 1rem 1rem;
    }
    .div_container_lotto_results {
        margin-top: 2.3rem;
        width: 90%;
    }
    .div_poye_wait_scc {
        font-size: 14px;
        padding: 10px 5px;
    }
    .div_poye_winlose {
        font-size: 14px;
        padding: 10px 5px;
    }
    .btn_poye {
        font-size: 12px;
    }
    .close_huay {
        font-size: 11px !important;
        // margin-top: 25px;
    }
    .close_huay_real {
        font-size: 11px !important;
        // margin-top: 25px;
    }
    .icon_lotto {
        width: 30px;
    }
    .icon_lotto_result {
        // width: 30px;
        // height: 20px;
    }
    .btn_set_price {
        padding: 4px 3px !important;
        font-size: 12px !important;
    }
    .container_lotto {
        margin-top: 5.4rem;
        margin-bottom: 10rem;
    }
    .container_lottobuy {
        margin-top: 5.5rem;
        margin-bottom: 5rem;
    }
    .txt_list_lotto {
        font-size: 14px;
    }
    .txt_head_list {
        font-size: 14px !important;
    }
    .txt_head_list2 {
        font-size: 14px !important;
    }
    .btn_front_black {
        padding: 5px 7px !important;
    }
    .list_selected {
        font-size: 11px !important;
    }
    .btn_3bon_lang_tod_1 {
        min-width: 0px !important;
    }
    .div_winglang_wing_bon {
        justify-content: space-between;
    }
    .grid_winglang_wing_bon {
        padding: 0px;
    }
    .btn_winglang_wing_bon {
        padding: 0px;
    }
}